/**
 * 存取遠端數據的父類別，無法直接使用
 * 
 * @module _remote
 * @extends module:_widget
 */
$.widget("tcg._remote", $.tcg._widget, {
    version: "2.2.21",
    options: {
        /**
         * @memberOf module:_remote
         * @property {string} [url=0] - ajax url
         * @instance
         */
    	url: null,
    	/**
         * @memberOf module:_remote
         * @property {string} [method='GET'] - ajax method
         * @instance
         */
    	method: 'GET',
    	/**
         * @memberOf module:_remote
         * @property [queryParams=null] - ajax data
         * @instance
         */
    	queryParams: null,
        /**
         * @memberOf module:_remote
         * @property [queryParamsStringify=false] - ajax data字串化
         * @instance
         */
        queryParamsStringify: false,
        /**
         * @memberOf module:_remote
         * @property [ajaxOption={}] - ajax option
         * @instance
         */
        ajaxOption: {},
    	/**
         * @memberOf module:_remote
         * @property {object} [data=null] - 資料
         * @instance
         */
    	data: null,
    	/**
         * @memberOf module:_remote
         * @property {boolean} [autoLoad=true] - 當url不為null時，是否自動執行ajax請求
         * @instance
         */
    	autoLoad: true,
        /**
         * @memberOf module:_remote
         * @property {function} [loader=null] - 遠端資料獲取後，資料處理處理、回傳的函式
         * @instance
         */
        loader: null
    },
    _create: function () {
        this._super();
    },
    _init: function () {
        this._super();

        this.options.data = this._constrainData(this.options.data);
        this.options.autoLoad = this._constrainAutoLoad(this.options.autoLoad);
        this.options.queryParamsStringify = this._constrainQueryParamsStringify(this.options.queryParamsStringify);
        this.options.ajaxOption = this._constrainAjaxOption(this.options.ajaxOption);

        if(this.options.autoLoad && this.options.url){
            this.load();
        }
    },
    /**
     * <h4>重新加載遠端資料</h4>null
     *
     * @instance
     *
     * @param {*} queryParams - 請求參數
     */
    reload: function (queryParams) {

        //  更新請求參數
        this.options.queryParams = queryParams != undefined ? queryParams : this.options.queryParams;

        //  加載遠端資料
        return this.load();
    },
    /**
     * <h4>加載遠端資料</h4>
     *
     * @instance
     * 
     * @return {promise} ajax promise
     */
    load: function () {
        return this._load(this.options.queryParams);
    },
    _load: function (data, beforeSend) {
    	var _this = this;

        return $.ajax($.extend(this.options.ajaxOption, {
            url: this.options.url,
            method: this.options.method,
            data: this.options.queryParamsStringify ? JSON.stringify(data) : data,
            beforeSend: function (jqXHR, settings) {
                /**
                 * 發送請求獲取遠端數據前，觸發的事件
                 *
                 * @event beforeLoad
                 *
                 * @param {Event} event - 事件
                 * @param {jqXHR} jqXHR - jqXHR
                 */
                 _this._trigger("beforeLoad", null, jqXHR);

                 // 執行傳入的beforeSend
                 typeof beforeSend == 'function' ? beforeSend(jqXHR, settings) : null;
                 //  清空舊數據
                _this.options.data = {};
            }
        })).always(function (rs, jqXHR) {
            /**
             * 遠端資料獲取後，觸發的事件
             *
             * @event afterSend
             *
             * @param {Event} event - 事件
             * @param {*} rs - 遠端資料
             * @param {jqXHR} jqXHR - jqXHR
             */
            _this._trigger("afterLoad", null, rs, jqXHR);
        }).done(function(rs, jqXHR){
            /**
             * 遠端資料獲取成功後，觸發的事件
             *
             * @event loadSuccess
             *
             * @param {Event} event - 事件
             * @param {*} rs - 遠端資料
             * @param {jqXHR} jqXHR - jqXHR
             */
			_this._trigger("loadSuccess", null, rs, jqXHR);

            //  loader
            var data = _this.options.loader && (typeof _this.options.loader == "function") ? _this.options.loader(rs) : rs;

            //  set data
            _this.setData(data);
        }).fail(function(jqXHR){
            /**
             * 遠端資料獲取成功後，觸發的事件
             *
             * @event loadFailure
             *
             * @param {Event} event - 事件
             * @param {jqXHR} jqXHR - jqXHR
             */
            _this._trigger("loadFailure", null, jqXHR);
        });
    },
    /**
     * <h4>取得所有資料</h4>
     *
     * @instance
     *
     * @return {Array} 所有資料
     */
    getData: function () {
        return this.options.data;
    },
    /**
     * <h4>設定所有資料</h4>
     *
     * @instance
     *
     * @param {Array} data - 所有資料
     */
    setData: function (data) {
        this.options.data = this._constrainData(data);
        this._showData();
    },
    /*
     * <h4>根據data顯示UI</h4>
     *
     * @instance
     */
    _showData: function () {
        //
    },
    /**
     * <h4>判斷是否自動調用遠端數據</h4>
     *
     * @instance
     *
     * @return {boolean} 是否自動調用遠端數據
     */
    isAutoLoad: function () {
        return this.options.autoLoad;
    },
    _constrainUrl: function (url) {
        return typeof url == "string" ? url : null;
    },
    _constrainMethod: function (method) {
        return ['GET','POST'].indexOf(method) != -1 ? method : 'GET';
    },
    _constrainQueryParamsStringify: function (queryParamsStringify) {
        return typeof queryParamsStringify == 'boolean' ? queryParamsStringify : false;
    },
    _constrainAjaxOption: function (ajaxOption) {
        return typeof ajaxOption == 'object' ? ajaxOption : {};
    },
    _constrainAutoLoad: function (autoLoad) {
        return typeof autoLoad == "boolean" ? autoLoad : true;
    },
    _constrainData: function (data) {
        return data;
    }
});
