/**
 *
 * @module panel
 */
$.widget("tcg.panel", {
    version: "2.2.21",
    defaultElement: "<div></div>",
    options: {

    }
});
