/**
 * <p>jquery-tcg-widget套件裡，所以元件的最高父類別，該最高父類別又繼承自{@link http://jqueryui.com/|jqueryui}裡的widget</p>
 * <p>若是有志學習，可以參考{@link http://www.runoob.com/jqueryui/api-jquery-widget.html|jQuery UI API - 部件庫(Widget Factory)}</p>
 *
 * @module _widget
 */
$.widget("tcg._widget", {
    version: "2.2.21",
    options: {
        /**
         * @memberOf module:_widget
         * @property {number} [size=32] - 元件尺寸。當前元件的尺寸有24,26,28,30,32,34，若不是這六個尺寸的數值，則會使用預設的大小。
         * @instance
         */
        size: 32,
        /**
         * @memberOf module:_widget
         * @property {number|string} [width=null] - 元件寬度
         * @instance
         */
        width: null,
        /**
         * @memberOf module:_widget
         * @property {boolean} [disabled=false] - 元件禁用
         * @instance
         */
        disabled: false
    },
    defaultSize: 32,
    allSize: [16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
    allSizeClasses: ['tcg-s16','tcg-s18','tcg-s20','tcg-s22','tcg-s24','tcg-s26','tcg-s28','tcg-s30','tcg-s32','tcg-s34','tcg-s36'],
    originClass: null,
    originStyle: null,
    _create: function () {
        this._super();

        this.originClass = this.element.attr("class");
        this.originStyle = this.element.attr("style");

        this.widget().addClass("tcg-widget");
    },
    _init: function () {
        this._super();

        this.options.size = this._constrainSize(this.options.size);
        this.options.width = this._constrainWidth(this.options.width);
        this.options.disabled = this._constrainDisabled(this.options.disabled);
    },
    widget: function () {
        return this.element;
    },
    /**
     * <h4>元件重繪</h4>
     *
     * @instance
     */
    refresh: function () {},
    /**
     * <h4>元件銷毀</h4>
     *
     * @instance
     *
     * @method destroy
     */
    _destroy: function() {
        this._off(this.widget());
        this.widget().attr("class", this.originClass);
        this.widget().attr("style", this.originStyle);
    },
    /**
     * <h4>取得元件尺寸</h4>
     * @instance
     *
     * @return {integer|null} 元件尺寸
     */
    getSize: function () {
        return this.options.size;
    },
    /**
     * <h4>取得按鈕寬度</h4>
     *
     * @instance
     *
     * @return {string|number} 寬度
     */
    getWidth: function () {
        return this.options.width;
    },
    /**
     * <h4>判斷元件是否禁用</h4>
     *
     * @instance
     *
     * @return {boolean} 是否禁用
     */
    isDisabled: function () {
        return this.options.disabled;
    },
    _constrainSize: function (size) {
        size = Number(size);

        if(!size){
            return this.defaultSize;
        }else if(this.allSize.indexOf(size) == -1){
            return this.defaultSize;
        }else{
            return size;
        }
    },
    _constrainWidth: function (width) {
        return width;
    },
    _constrainDisabled: function (disabled) {
        return typeof disabled == 'boolean' ? disabled : false;
    }
});
