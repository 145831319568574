/**
 *
 * @module numberBox
 * @extends module:textbox
 */
$.widget("tcg.numberBox", $.tcg.textbox, {
    version: "2.2.21",
    options: {
        /**
         * @memberOf module:numberBox
         * @property {number} [min=0] - 最小值
         * @instance
         */
        min: 0,
        /**
         * @memberOf module:numberBox
         * @property {number} [max=null] - 最大值
         * @instance
         */
        max: null,
        /**
         * @memberOf module:numberBox
         * @property {string} [prefix=''] - 前綴
         * @instance
         */
        prefix: '',
        /**
         * @memberOf module:numberBox
         * @property {string} [suffix=''] - 後綴
         * @instance
         */
        suffix: '',
        /**
         * @memberOf module:numberBox
         * @property {string} [precision=0] - 精確度
         * @instance
         */
        precision: 2,
        /**
         * @memberOf module:numberBox
         * @property {function(value)} [formatter=function(value, n, x)] - 格式化函式，將value格式化成自定的格式<code>function(value)</code>
         * @instance
         */
        formatter: function(value, n, x){
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
            return Number(value).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
        }
    },
    _create: function () {
        this._super();

        this._off(this.widget(), "keypress input.tcg-input");
        this._off(this.widget(), "keydown input.tcg-input");
        this._off(this.widget(), "keyup input.tcg-input");
		this._on(this.widget(), {
			"keypress input.tcg-input": function(e){
			    var code = e.which || e.charCode || e.keyCode || 0;

                //  限制只能輸入0~9, 小數點、ENTER、DELETE
                return (code >= 48 && code <= 57) || code == 46 || code == 13 || code == 8;
            },
            "keydown input.tcg-input": function(e){
                var value = this.input[0].value.trim() == "" ? null : Number(this.input[0].value.trim());

                this._trigger("keydown", e, {value:value});
            },
            "keyup input.tcg-input": function(e){
                var value = this.input[0].value.trim() == "" ? null : Number(this.input[0].value.trim());

                this._trigger("keyup", e, {value:value});
                this._trigger("change", e, {value:value});

                if(e.keyCode == 13){
                    this.setValue(value);

                    this._trigger("enter", e, {value: value});
                }
            }
        });
    },
    _init: function () {

        this._super();
    },
    _setText: function (text) {

        if(text == null || text == undefined || text.toString().trim() == ""){
            this.input[0].value = "";
        }else{
            //	formatter
            text = this.options.formatter(text, this.options.precision, 3);

            //  串接前後綴
            this.input[0].value = text != null ? this.options.prefix + text + this.options.suffix : '';
        }
    },
    getValue: function () {
        return this.options.value;
    },
    _constrainMin: function (min) {
        return $.isNumeric(min) && min < this.options.max ? Number(min) : 0;
    },
    _constrainMax: function (max) {
        return $.isNumeric(max) && max > this.options.min ? Number(max) : null;
    },
    _constrainValue: function (value) {
        if(value == null || value == undefined || value.toString().trim() == ""){
            return "";
        }else if(!$.isNumeric(value)){
            return this.options.min;
        }else if(value < this.options.min){
            return this.options.min;
        }else if(this.options.max != null && value > this.options.max){
            return this.options.max;
        }else{
            return Number(value);
        }
    },
    _constrainPrefix: function (prefix) {
        return typeof prefix == "string" ? prefix : "";
    },
    _constrainSuffix: function (suffix) {
        return typeof suffix == "string" ? suffix : "";
    },
    _constrainPrecision: function (precision) {
        return Number.isInteger(precision) ? Number(precision) : 0;
    },
    _constrainFormatter: function (formatter) {
        return typeof formatter == "function" ? formatter : null;
    }
});