/**
 *
 * @module datepicker
 * @extends module:textbox
 */
$.widget("tcg.datepicker", $.tcg.textbox, {
    version: "2.2.21",
    options: {
        value: null,
        /**
         * <p>"y"：年</p>
         * <p>"M"：月</p>
         * <p>"d"：日</p>
         * <p>"a"：時間。上午、下午</p>
         * <p>"h"：時。24小時制</p>
         * <p>"H"：時。12小時制</p>
         * <p>"m"：分</p>
         * <p>"s"：秒</p>
         * <p>"S"：毫秒</p>
         * <p>"E"：星期</p>
         *
         * @memberOf module:datepicker
         * @property {string} [format='yyyy-MM-dd'] - 日期文字格式
         * @instance
         */
        format: "yyyy-MM-dd",
        /**
         * <p>"y"：年</p>
         * <p>"M"：月</p>
         * <p>"d"：日</p>
         * <p>"a"：時間。上午、下午</p>
         * <p>"h"：時。24小時制</p>
         * <p>"H"：時。12小時制</p>
         * <p>"m"：分</p>
         * <p>"s"：秒</p>
         * <p>"S"：毫秒</p>
         * <p>"E"：星期</p>
         *
         * @memberOf module:datepicker
         * @property {string} [headerFormat='yyyy年MM月'] - header文字格式
         * @instance
         */
        headerFormat: "yyyy年MM月",
        /**
         * @memberOf module:datepicker
         * @property {boolean} [range=false] - 可以選擇開始、結束時間
         * @instance
         */
        range: false,
        /**
         * @memberOf module:datepicker
         * @property {boolean} [editableTime=false] - 可以編輯時、分、秒
         * @instance
         */
        editableTime: true,
        /**
         * <p>若是傳入date物件，則該date即為最大可選取日期；若是傳入字串，格式如下：</p>
         * <p>"+7d"：7天後</p>
         * <p>"+1m +7d"：1個月又7天後</p>
         * <p>"+1y +2m +3d"：1年2個月又3天後</p>
         * <p>"-7d"：7天前</p>
         *
         * @memberOf module:datepicker
         * @property {string | Date} [maxDate=null] - 最大可選取日期
         * @instance
         */
        maxDate: null,
        /**
         * <p>若是傳入date物件，則該date即為最小可選取日期；若是傳入字串，格式如下：</p>
         * <p>"+7d"：7天後</p>
         * <p>"+1m +7d"：1個月又7天後</p>
         * <p>"+1y +2m +3d"：1年2個月又3天後</p>
         * <p>"-7d"：7天前</p>
         *
         * @memberOf module:datepicker
         * @property {string | Date} [minDate=null] - 最小可選取日期
         * @instance
         */
        minDate: null,
        /**
         * @memberOf module:datepicker
         * @property {string} [buttonText='确定'] - 按鈕文字
         * @instance
         */
        buttonText: '确定',
        /**
         * @memberOf module:datepicker
         * @property {Array} [monthNamesShort='1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'] - 月份名稱
         * @instance
         */
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        /**
         * @memberOf module:datepicker
         * @property {Array} [dayNames='星期日','星期一','星期二','星期三','星期四','星期五','星期六'] - 星期名稱
         * @instance
         */
        dayNames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        /**
         * @memberOf module:datepicker
         * @property {Array} [dayNamesShort='日','一','二','三','四','五','六'] - 星期名稱
         * @instance
         */
        dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
        /**
         * @memberOf module:datepicker
         * @property {string} [calenderAlign='left'] - 日曆貼齊方向。left靠左、right靠右
         * @instance
         */
        calenderAlign: 'left'
    },
    popup: null,
    //  左值
    leftValue: null,
    //  右值
    rightValue: null,
    //  左邊日曆的月份
    leftCalendar: null,
    //  右邊日曆的月份
    rightCalendar: null,
    _create: function () {
        var popup = '';
        this._super();

        popup += '<div class="tcg-inner-widget tcg-popup">';
        popup += '<div class="popup-wrap">';
        popup += '<div class="popup-body"></div>';
        popup += '<div class="popup-header"><div class="popup-arrow"></div></div>';
        popup += '</div>';
        popup += '</div>';
        this.popup = $(popup).appendTo(this.widget());

        this._off(this.widget(), "focusout input.tcg-input");
        this._off(this.widget(), "focusin input.tcg-input");
        this._on(this.widget(), {
            "mousedown input.tcg-input": function (e) {
                this.isOpenCalendar() ? this.closeCalendar() : this.openCalendar();
            },
            "focusin .time input": function (e) {
                e.currentTarget.select();
            },
            "focusout .hour": function (event) {
                event.currentTarget.value = event.currentTarget.value <= 23 ? event.currentTarget.value : 23;
            },
            "focusout .minute": function (event) {
                event.currentTarget.value = event.currentTarget.value <= 59 ? event.currentTarget.value : 59;
            },
            "focusout .second": function (event) {
                event.currentTarget.value = event.currentTarget.value <= 59 ? event.currentTarget.value : 59;
            },
            "click .pre-month-button": function (event) {
                var index = $(event.currentTarget).parent(".cal").index();
                var calendar = this._getPrevMonth(index == 0 ? this.leftCalendar : this.rightCalendar),
                    left = this._getFirstDayOfMonth(this.leftCalendar);

                if (index == 0) {
                    this.leftCalendar = calendar;
                } else if (this.options.range && index == 1 && calendar.getTime() > left.getTime()) {
                    this.rightCalendar = calendar;
                } else if (this.options.range && index == 1 && calendar.getTime() <= left.getTime()) {
                    calendar = this.rightCalendar;
                }

                this.popup.find(".tcg-calendar-content .cal:eq(" + index + ")").html(this._generateCalendar(calendar));
                this.popup.find(".tcg-calendar-header .cal:eq(" + index + ") h6").html(this._format(calendar, this.options.headerFormat));
            },
            "click .next-month-button": function (event) {
                var index = $(event.currentTarget).parent(".cal").index();
                var calendar = this._getNextMonth(index == 0 ? this.leftCalendar : this.rightCalendar),
                    right = this._getFirstDayOfMonth(this.rightCalendar);

                if (index == 1) {
                    this.rightCalendar = calendar;
                } else if (this.options.range && index == 0 && calendar.getTime() < right.getTime()) {
                    this.leftCalendar = calendar;
                } else if (this.options.range && index == 0 && calendar.getTime() >= right.getTime()) {
                    calendar = this.leftCalendar;
                }

                this.popup.find(".tcg-calendar-content .cal:eq(" + index + ")").html(this._generateCalendar(calendar));
                this.popup.find(".tcg-calendar-header .cal:eq(" + index + ") h6").html(this._format(calendar, this.options.headerFormat));
            },
            "click .ok-button": function (event) {

                //  1.複選
                //  2.單選
                if (this.options.range) {
                    var $start = this.popup.find("li.start"),
                        $end = this.popup.find("li.end");
                    var $leftCal = this.popup.find(".tcg-calendar-footer .cal:eq(0)"),
                        $rightCal = this.popup.find(".tcg-calendar-footer .cal:eq(1)");

                    if ($start.length != 0 && $end.length != 0) {
                        //  set value
                        this.setValue([
                            new Date($start.data("year"), $start.data("month"), $start.data("day"),
                                $leftCal.find(".hour").val(), $leftCal.find(".minute").val(), $leftCal.find(".second").val()),
                            new Date($end.data("year"), $end.data("month"), $end.data("day"),
                                $rightCal.find(".hour").val(), $rightCal.find(".minute").val(), $rightCal.find(".second").val())]);
                    }
                    this.closeCalendar();
                } else {
                    var $active = this.popup.find("li.active");
                    var $cal = this.popup.find(".tcg-calendar-footer .cal:eq(1)");

                    if ($active.length != 0) {
                        this.setValue(new Date(
                            $active.data("year"), $active.data("month"), $active.data("day"),
                            $cal.find(".hour").val(), $cal.find(".minute").val(), $cal.find(".second").val())
                        );
                    }
                    this.closeCalendar();
                }
            },
            "click .tcg-calendar-content li": function (event) {

                //  1.複選
                //  2.單選
                if (this.options.range) {
                    if (this.popup.find("li.start, li.end").length == 0) {
                        $(event.currentTarget).addClass("start").attr("selected", true);
                    } else if (this.popup.find("li.end").length != 0) {
                        this.popup.find("li").removeClass("start end").removeAttr("selected");
                        $(event.currentTarget).addClass("start").attr("selected", true);
                    } else if (this.popup.find("li.start").length != 0) {
                        var $start = this.popup.find("li.start");
                        var $end = $(event.currentTarget).addClass("end").attr("selected", true);
                        var $leftCal = this.popup.find(".tcg-calendar-footer .cal:eq(0)");
                        var $rightCal = this.popup.find(".tcg-calendar-footer .cal:eq(1)");
                        var $temp, $li, selected = false;

                        //  若是開始時間比結束時間大，則對調
                        if (new Date($start.data().year, $start.data().month, $start.data().day).getTime() > new Date($end.data().year, $end.data().month, $end.data().day).getTime()) {
                            $start.removeClass("start").addClass("end");
                            $end.removeClass("end").addClass("start");

                            $temp = $start, $start = $end, $end = $temp;
                        }

                        //  增加selected class，是為了套用css用
                        $li = this.popup.find(".tcg-calendar-content li:not([disabled])");
                        for (var i = 0, il = $li.length; i < il; i++) {
                            selected = $li.eq(i).hasClass("start") && !selected ? true : selected;
                            $li.eq(i).attr("selected", selected);
                            selected = $li.eq(i).hasClass("end") && selected ? false : selected;
                        }

                        //  set value
                        this.setValue([
                            new Date($start.data("year"), $start.data("month"), $start.data("day"),
                                $leftCal.find(".hour").val(), $leftCal.find(".minute").val(), $leftCal.find(".second").val()),
                            new Date($end.data("year"), $end.data("month"), $end.data("day"),
                                $rightCal.find(".hour").val(), $rightCal.find(".minute").val(), $rightCal.find(".second").val())]);

                        //  close popup
                        this.closeCalendar();
                    }
                } else {
                    var data = event.currentTarget.dataset;
                    var $cal = this.popup.find(".tcg-calendar-footer .cal:eq(1)");

                    //  set value
                    this.setValue(new Date(
                        data.year, data.month, data.day,
                        $cal.find(".hour").val(), $cal.find(".minute").val(), $cal.find(".second").val())
                    );

                    //  close popup
                    this.closeCalendar();
                }
            }
        });
    },
    _init: function () {

        this.options.range = this._constrainRange(this.options.range);
        this.options.format = this._constrainFormat(this.options.format);
        this.options.headerFormat = this._constrainHeaderFormat(this.options.headerFormat);
        this.options.editableTime = this._constrainEditableTime(this.options.editableTime);
        this.options.minDate = this._constrainMinDate(this.options.minDate);
        this.options.maxDate = this._constrainMaxDate(this.options.maxDate);
        this.options.buttonText = this._constrainButtonText(this.options.buttonText);
        this.options.monthNamesShort = this._constrainMonthNamesShort(this.options.monthNamesShort);
        this.options.dayNames = this._constrainDayNames(this.options.dayNames);
        this.options.dayNamesShort = this._constrainDayNamesShort(this.options.dayNamesShort);
        this.options.calenderAlign = this._constrainCalendarAlign(this.options.calenderAlign);
        this.options.editable = false;
        this.options.icon = "calendar";

        this._super();
    },
    refresh: function () {
        this._super();

        this.widget().addClass("tcg-datepicker");

        this._refreshPopup();

        //this.openCalendar();
    },
    _refreshPopup: function () {
        var html = '',
            week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

        html += '<div class="tcg-calendar">';
        html += '   <div class="tcg-calendar-header">';
        for (var i = 0; i < 2; i++) {
            html += '       <div class="cal">';
            html += '           <span class="pre-month-button"><span></span></span>';
            html += '           <span class="next-month-button"><span></span></span>';
            html += '           <h6></h6>';
            html += '       </div>';
        }
        // html += '       <div class="tcg-clearfix"></div>';
        html += '   </div>';
        html += '   <div class="tcg-calendar-week">';
        for (var i = 0; i < 2; i++) {
            html += '       <div class="cal">';
            html += '           <ul>';
            for (var j = 0; j < 7; j++) {
                html += '<li><span class="' + week[j] + '">' + this.options.dayNamesShort[j] + '</span></li>';
            }
            html += '           </ul>'
            html += '       </div>';
        }
        // html += '       <div class="tcg-clearfix"></div>';
        html += '   </div>';
        html += '   <div class="tcg-calendar-content">';
        for (var i = 0; i < 2; i++) {
            html += '       <div class="cal"></div>';
        }
        // html += '       <div class="tcg-clearfix"></div>';
        html += '   </div>';
        html += '   <div class="tcg-calendar-footer">';
        for (var i = 0; i < 2; i++) {
            html += '       <div class="cal">';
            html += '           <div class="in">';
            html += '               <div class="time">'
            html += '                   <span>时间：</span>';
            html += '                   <input type="text" class="hour" value="00" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">：';
            html += '                   <input type="text" class="minute" value="00" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">：';
            html += '                   <input type="text" class="second" value="00" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">';
            html += '               </div>';
            html += i == 1 ? '      <div class="tcg-inner-widget tcg-button tcg-corner-all ok-button">' + this.options.buttonText + '</div>' : '';
            html += '               <div class="tcg-clearfix"></div>';
            html += '           </div>';
            html += '       </div>';
        }
        // html += '       <div class="tcg-clearfix"></div>';
        html += '   </div>';
        html += '</div>';

        this.popup.find(".popup-body").html(html);
        this.popup.find(".tcg-calendar").attr("range", this.options.range);
        this.popup.find(".time").css("display", this.options.editableTime ? 'block' : 'none');

        if (this.options.calenderAlign == "right") {
            this.popup.find(".popup-body").css("right", -1 * this.options.width + "rem");
            this.popup.find(".popup-arrow").css("left", (this.options.width - 20) / 100 + "rem");
        }
    },
    _destroy: function () {

    },
    _setText: function (text) {
        if (text instanceof Array) {
            this.input[0].value = this._format(text[0], this.options.format) + ' - ' + this._format(text[1], this.options.format);
        } else if (text instanceof Date) {
            this.input[0].value = this._format(text, this.options.format);
        } else {
            this.input[0].value = '';
        }
    },
    _setValue: function (value) {
        value = this._constrainValue(value);

        if (value instanceof Array) {
            this.leftValue = value[0];
            this.rightValue = value[1];
        } else if (value instanceof Date) {
            this.leftValue = null;
            this.rightValue = value;
        } else {
            this.leftValue = this.rightValue = null;
        }

        this._setText(value);
    },
    setValue: function (value) {
        this._setValue(value);

        this._trigger("change", null, { value: this.getValue() });
    },
    getValue: function () {
        if (this.options.range) {
            return [this.leftValue, this.rightValue];
        } else {
            return this.rightValue;
        }
    },
    getMaxDate: function () {
        return this.options.maxDate;
    },
    getMinDate: function () {
        return this.options.minDate;
    },
    openCalendar: function () {
        //  設定右邊日曆月份，若為null則設為當月
        this.leftCalendar = this.leftValue ? this.leftValue : this._getDay(0);
        //  設定左邊日曆，若為null則設為當月
        this.rightCalendar = this.rightValue ? this.rightValue : this._getDay(0);

        //  若有開始、結束日期，則顯示右邊日曆
        if (this.options.range) {
            //  產生右邊日曆UI
            this.popup.find(".tcg-calendar-content .cal:eq(1)").html(this._generateCalendar(this.rightCalendar));
            this.popup.find(".tcg-calendar-header .cal:eq(1) h6").html(this._format(this.rightCalendar, this.options.headerFormat));
            this.popup.find(".tcg-calendar-footer .cal:eq(1) input.hour").val(this.rightCalendar.getHours());
            this.popup.find(".tcg-calendar-footer .cal:eq(1) input.minute").val(this.rightCalendar.getMinutes());
            this.popup.find(".tcg-calendar-footer .cal:eq(1) input.second").val(this.rightCalendar.getSeconds());

            //  若是左邊日曆與右邊日曆是同月份，則右邊日曆加一個月份
            if (this.leftCalendar.getFullYear() == this.rightCalendar.getFullYear() &&
                this.leftCalendar.getMonth() == this.rightCalendar.getMonth()) {
                this.leftCalendar = new Date(
                    this.leftCalendar.getFullYear() - (this.leftCalendar.getMonth() == 0 ? 1 : 0),
                    this.leftCalendar.getMonth() == 1 ? 0 : this.leftCalendar.getMonth() - 1,
                    this.leftCalendar.getDate(),
                    this.leftCalendar.getHours(),
                    this.leftCalendar.getMinutes(),
                    this.leftCalendar.getSeconds());
            }
        }
        //  產生左邊日曆UI
        this.popup.find(".tcg-calendar-content .cal:eq(0)").html(this._generateCalendar(this.leftCalendar));
        this.popup.find(".tcg-calendar-header .cal:eq(0) h6").html(this._format(this.leftCalendar, this.options.headerFormat));
        this.popup.find(".tcg-calendar-footer .cal:eq(0) input.hour").val(this.leftCalendar.getHours());
        this.popup.find(".tcg-calendar-footer .cal:eq(0) input.minute").val(this.leftCalendar.getMinutes());
        this.popup.find(".tcg-calendar-footer .cal:eq(0) input.second").val(this.leftCalendar.getSeconds());

        //  open
        this.popup.addClass("tcg-active");
    },
    closeCalendar: function () {
        this.popup.removeClass("tcg-active");
    },
    isOpenCalendar: function () {
        return this.popup.hasClass("tcg-active");
    },
    _getDay: function (day) {
        var d = new Date(Date.now() + day * 1000 * 60 * 60 * 24);
        return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
    },
    _getFirstDayOfMonth: function (date) {
        return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    },
    _getLastDayOfMonth: function (date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0);
    },
    _getPrevMonth: function (date) {
        if (date.getMonth() == 0) {
            return new Date(date.getFullYear() - 1, 11, 1, 0, 0, 0);
        } else {
            return new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0);
        }
    },
    _getNextMonth: function (date) {
        if (date.getMonth() == 11) {
            return new Date(date.getFullYear() + 1, 0, 1, 0, 0, 0);
        } else {
            return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0);
        }
    },
    _generateCalendar: function (month) {
        //  一個月的總天數
        var days = this._getLastDayOfMonth(month).getDate();
        //  該月第一天是禮拜幾   86400000是一天的时间毫秒
        var firstDay = this._getFirstDayOfMonth(month), offset = firstDay.getDay();
        var max = this.options.maxDate ? this.options.maxDate.getTime() : null,
            min = this.options.minDate ? this.options.minDate.getTime() : null,
            left = this.leftValue ? this.leftValue.getTime() : null,
            right = this.rightValue ? this.rightValue.getTime() : null,
            now = firstDay.getTime() - 86400000, disabled;
        var html = [], attr, clazz;

        html.push('<ul>');
        for (var i = 0; i < offset; i++) {
            html.push('<li disabled>&nbsp;</li>');
        }

        for (var i = 1, il = days; i <= il; i++) {
            attr = '', clazz = '';
            now += 86400000;

            //  判斷日期是否disabled
            if (max != null && now > max) {
                attr += ' disabled ';
            } else if (min != null && now < min) {
                attr += ' disabled ';
            }

            //  判斷日期是否選擇
            if (this.options.range && left != null && right != null && now >= left && now <= right) {
                attr += ' selected';
            }
            //  判斷是否開始日期
            if (this.options.range && left != null && Math.floor(left / 86400000) == Math.floor(now / 86400000)) {
                clazz += ' start';
            }
            //  判斷是否結束日期
            if (this.options.range && right != null && Math.floor(right / 86400000) - Math.floor(now / 86400000) == 1) {
                clazz += ' end';
            }
            //  判斷是否單選日期

            if (!this.options.range && right != null && Math.floor(right / 86400000) == Math.floor(now / 86400000)) {
                attr += ' selected';
                clazz += ' active';
            }

            attr += ' data-year="{year}"'.replace("{year}", month.getFullYear());
            attr += ' data-month="{month}"'.replace("{month}", month.getMonth());
            attr += ' data-day="{day}"'.replace("{day}", i);
            attr += ' class="' + clazz + '"'

            html.push('<li' + attr + '><span>' + i + '</span></li>');

            if ((i + offset) % 7 == 0) { html.push('</ul><ul>'); }
        }
        html.push('</ul>');

        return html.join('');
    },
    /*
     * 限制參數只能是Date物件
     *
     * @param restrict - 限制的參數
     * @return {*}
     * @private
     */
    _restrictToDate: function (restrict, hh, mm, ss) {
        var today = new Date();
        var y, m, d;

        hh = hh != null && hh != undefined ? hh : 0;
        mm = mm != null && mm != undefined ? mm : 0;
        ss = ss != null && ss != undefined ? ss : 0;

        //  1.若是Date物件，直接回傳
        //  2.若是String物件，則轉換為Date後回傳
        //  3.其他則回傳null
        if (restrict instanceof Date) {
            return restrict;
        } else if (typeof restrict == "string") {
            y = /[+-]\d+y/.exec(restrict);
            m = /[+-]\d+m/.exec(restrict);
            d = /[+-]\d+d/.exec(restrict);

            y = y ? y[0].replace("y", "") * 1 : 0;
            m = m ? m[0].replace("m", "") * 1 : 0;
            d = d ? d[0].replace("d", "") * 1 : 0;

            return new Date(today.getFullYear() + y, today.getMonth() + m, today.getDate() + d, hh, mm, ss);
        } else {
            return null;
        }
    },
    /*
     * 格式化日期
     * @param pattern
     * @return {*}
     * @private
     */
    _format: function (value, pattern) {
        //  Declare
        var year = value.getFullYear();
        var month = value.getMonth() + 1;
        var day = value.getDate();
        var time = parseInt(value.getHours() / 12);
        var hour = value.getHours();
        var minute = value.getMinutes();
        var second = value.getSeconds();
        var week = value.getDay();
        var millisecond = value.getMilliseconds();

        //  zero fill
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hour = hour < 10 ? "0" + hour : hour;
        minute = minute < 10 ? "0" + minute : minute;
        second = second < 10 ? "0" + second : second;

        // Mon Sep 17 2012 15:44:16 GMT+0800 (CST) to 2012-09-17 15:44:16
        pattern = pattern.replace("yyyy", year);
        pattern = pattern.replace("MM", month);
        pattern = pattern.replace("dd", day);
        pattern = pattern.replace("a", ['上午', '下午'][time]);
        pattern = pattern.replace("HH", hour > 13 ? hour % 12 : hour);
        pattern = pattern.replace("hh", hour);
        pattern = pattern.replace("mm", minute);
        pattern = pattern.replace("ss", second);
        pattern = pattern.replace("SSS", millisecond);
        pattern = pattern.replace("E", this.options.dayNames[week]);
        return pattern;
    },
    _constrainValue: function (value) {
        //  1.有開始時間、結束時間，且資料型態是Array
        //  2.有開始時間、結束時間，且資料型態是Date
        //  3.唯一時間，且資料型態是Date或是String
        if (this.options.range && value instanceof Array) {
            var left = value[0], right = value[1];

            left = this._restrictToDate(left, 0, 0, 0); right = this._restrictToDate(right, 23, 59, 59);

            if (left != null) {
                //  確認left大於minDate
                left = this.options.minDate != null && this.options.minDate.getTime() > left.getTime() ? this.options.minDate : left;
                //  確認left小於maxDate
                left = this.options.maxDate != null && this.options.maxDate.getTime() < left.getTime() ? this.options.maxDate : left;
            }

            if (right != null) {
                //  確認right大於minDate
                right = this.options.minDate != null && this.options.minDate.getTime() > right.getTime() ? this.options.minDate : right;
                //  確認right小於maxDate
                right = this.options.maxDate != null && this.options.maxDate.getTime() < right.getTime() ? this.options.maxDate : right;
            }

            return [left, right];
        } else if (this.options.range && value instanceof Date) {
            //  確認value大於minDate
            value = this.options.minDate != null && this.options.minDate.getTime() > value.getTime() ? this.options.minDate : value;
            //  確認value小於maxDate
            value = this.options.maxDate != null && this.options.maxDate.getTime() < value.getTime() ? this.options.maxDate : value;

            return [value, null];
        } else if (!this.options.range && value instanceof String || value instanceof Date) {
            //  確認value為Date物件
            value = value instanceof Date ? value : this._restrictToDate(value);
            //  null即結束並回傳
            if (value == null) { return value; }
            //  確認value大於minDate
            value = this.options.minDate != null && this.options.minDate.getTime() > value.getTime() ? this.options.minDate : value;
            //  確認value小於maxDate
            value = this.options.maxDate != null && this.options.maxDate.getTime() < value.getTime() ? this.options.maxDate : value;

            return value;
        } else {
            return null;
        }
    },
    _constrainFormat: function (format) {
        return typeof format == 'string' ? format : 'yyyy-MM-dd';
    },
    _constrainHeaderFormat: function (headerFormat) {
        return typeof headerFormat == 'string' ? headerFormat : 'yyyy年MM月';
    },
    _constrainRange: function (ragne) {
        return typeof ragne == "boolean" ? ragne : false;
    },
    _constrainEditableTime: function (editableTime) {
        return typeof editableTime == "boolean" ? editableTime : true;
    },
    _constrainMaxDate: function (maxDate) {
        return this._restrictToDate(maxDate, 23, 59, 59);
    },
    _constrainMinDate: function (minDate) {
        return this._restrictToDate(minDate, 0, 0, 0);
    },
    _constrainButtonText: function (buttonText) {
        return typeof buttonText == 'string' ? buttonText : '确定';
    },
    _constrainMonthNamesShort: function (monthNamesShort) {
        return monthNamesShort instanceof Array && monthNamesShort.length > 12 ? monthNamesShort : ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
    },
    _constrainDayNames: function (dayNames) {
        return dayNames instanceof Array && dayNames.length > 7 ? dayNames : ['日', '一', '二', '三', '四', '五', '六'];
    },
    _constrainDayNamesShort: function (dayNamesShort) {
        return dayNamesShort instanceof Array && dayNamesShort.length > 7 ? dayNamesShort : ['日', '一', '二', '三', '四', '五', '六'];
    },
    _constrainCalendarAlign: function (calendarAlign) {
        return ['left', 'right'].indexOf(calendarAlign) != -1 ? calendarAlign : 'left';
    }
});