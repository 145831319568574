/**
 *
 * @module scrollbarCustomize
 * @extends module:_remote
 */
$.widget("tcg.scrollbarCustomize", $.tcg._widget, {
    version: "2.2.21",
    defaultElement: "<div></div>",
    options: {
        /**
         * @memberOf module:scrollbarCustomize
         * @property {string} [scrollbar={}] - jquery.scrollbar本身提供的參數
         * @see {@link http://gromo.github.io/jquery.scrollbar/}
         */
        scrollbar: {},

        /**
         * @memberOf module:scrollbarCustomize
         * @property {string} [scrollClass='scrollbar-inner'] - 套用樣式
         */
        scrollClass: 'scrollbar-inner',

        /**
         * @memberOf module:scrollbarCustomize
         * @property {object} [offsetYObj={}] - Y軸樣式調整
         */
        offsetYObj: {}
    },

    _create: function () {
        this._super();

        if (!!$(this).scrollbar && !!(typeof $(this).scrollbar === 'function')) {
            var scrollbar = (!!this.options.scrollbar && typeof this.options.scrollbar === 'object') ? this.options.scrollbar : {};
            var scrollClass = (!!this.options.scrollClass && typeof this.options.scrollClass === 'string') ? this.options.scrollClass : 'scrollbar-inner';
            $(this.element).addClass(scrollClass).scrollbar(scrollbar);
        }
    },

    _init: function () {
        this._super();

        if (!!$(this).scrollbar && !!(typeof $(this).scrollbar === 'function')) {
            var offsetYObj = this.options.offsetYObj;
            if (!!offsetYObj && typeof offsetYObj === 'object' && !!Object.keys(offsetYObj).length)
                $(this.element).parent().find('.scroll-y').css(offsetYObj);
        }
    },

    _destroy: function () {}
});
