/**
 *
 * @module menu
 * @extends module:_selectedWidget
 */
$.widget("tcg.menu", $.tcg._selectedWidget, {
    version: "2.2.21",
    options: {
        /**
         * @memberOf module:menu
         * @property {string} [padding=0] - padding。選項左右兩側內縮的大小
         * @instance
         */
        padding: 15,
        /**
         * @memberOf module:menu
         * @property {number} [spacing=0] - 選項間距。
         * @instance
         */
        spacing: 0
    },
    _create: function()  {
        this._super();

        //  事件監聽
        this._on(this.widget(), {
            "click li": function (e) {
                var index = Number(e.currentTarget.dataset.index),
                    isChange = this.options.index != index;

                this.options.index = index;
                this.widget().find("li[data-index='" + index + "']").attr("selected", true)
                    .siblings("li").attr("selected", false);

                /**
                 * 按鈕點擊時，觸發的事件
                 *
                 * @event click
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料
                 */
                this._trigger("click", e, {index: this.getIndex(), data: this.getSelectedData()});
                /**
                 * 按鈕點擊時，觸發的事件
                 *
                 * @event change
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料
                 */
                if(isChange){
                    this._trigger("change", e, {index: this.getIndex(), data: this.getSelectedData()});
                }
            }
        });
    },
    _init: function() {
        this._super();

        this.options.padding = this._constrainPadding(this.options.padding);
        this.options.spacing = this._constrainSpacing(this.options.spacing);

        this.refresh();
    },
    refresh: function () {

        this._refresh();

        this._refreshUl();
    },
    _refresh: function () {
        var clazz = "tcg-widget tcg-menu ";

        //  add 原本的class
        clazz += this.originClass;
        //  設定大小
        clazz += ' tcg-s' + this.options.size;

        //  設定class
        this.widget().attr("class", clazz);
        //  是否禁用
        this.widget().attr("disabled", this.options.disabled);
    },
    _refreshUl: function () {
        var html = '', data = this.options.data;

        html += '<ul>';
        for(var i = 0, j = data.length; i < j; i++){
            html += '<li data-index="' + i + '" ' + (i == this.options.index ? 'selected' : '') + '><a>' + data[i].text + '</a></li>';
        }
        html += '</ul>';

        this.widget().html(html);
        this.widget().find("li").css({
            width: parseFloat(this.options.width) / 100 + "rem",
            "padding-left": parseFloat(this.options.padding) / 100 + "rem",
            "padding-right": parseFloat(this.options.padding) / 100 + "rem",
            "margin-right": parseFloat(this.options.spacing) / 100 + "rem"
        });
        this.widget().find("li:last").css({
            "margin-right": 0
        });
    },
    _destroy: function() {

    },
    _constrainPadding: function (padding) {
        return $.isNumeric(padding) ? padding * 1 : 15;
    },
    _constrainSpacing: function (spacing) {
        return Number.isInteger(spacing) ? spacing * 1 : 0;
    }
});